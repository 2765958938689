import { Button, Link } from '@material-ui/core';
import React, { Component } from 'react';

class Topbar extends Component {
    state = {  }
    render() {
        return (
            <div className="topbar">
                <a className="topbar-logo-link" href="https://dashtastic.com" >
                    <img className="topbar-logo" src="logo.png" />
                    <span className="topbar-subtitle"> Support </span>
                </a>
                <Button className="topbar-link" variant="contained" color="primary">
                    New Ticket
                </Button>
            </div>
            
        );
    }
}

export default Topbar;