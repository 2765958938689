import { Paper, Typography, TextField, Button } from '@material-ui/core';
import React, { Component } from 'react';
import axios from 'axios';

class NewTicketForm extends Component {
    state = {
        subject: "",
        body: "",
        email: "",
        phone: "",
        emailIsValid: false,
        phoneIsValid: false,
        bodyIsValid: false
    }

    handleSubjectChange = event => { this.setState({subject: event.target.value}); }

    handleBodyChange = event => {
        this.setState({body: event.target.value});
        if (event.target.value.length > 0) {
            this.setState({bodyIsValid: true});
        } else {
            this.setState({bodyIsValid: false});
        }
    }

    handleEmailChange = event => {
        this.setState({email: event.target.value});
        const containsAt = event.target.value.includes("@");
        const containsDot = event.target.value.includes(".");
        const deconstructedEmail = event.target.value.split(".");
        const topLevel = deconstructedEmail[deconstructedEmail.length - 1];
        if (containsAt && containsDot && /^[a-z]+$/i.test(topLevel)) {
            this.setState({emailIsValid: true});
        } else {
            this.setState({emailIsValid: false});
        }
    }

    handlePhoneChange = event => {
        let phoneNumber = event.target.value//
        if (phoneNumber.length == 10) {
            this.setState({phone: `(${phoneNumber.substr(0,3)}) ${phoneNumber.substr(3,3)}-${phoneNumber.substr(6)}`})
        } else {
            this.setState({phone: event.target.value.replace("(","").replace(")","").replace("-","").replace(" ","")});
        }

        if (phoneNumber.length > 9 && /^\d+$/.test(phoneNumber)) {
            this.setState({phoneIsValid: true});
        } else {
            this.setState({phoneIsValid: false});
        }
    }

    createTicket = async data => {
        //Loading
        const response = await axios.post('/api/new', data)
        console.log(response.body);
    }

    handleSubmit = () => {
        console.log(this.state);
        if (this.state.bodyIsValid && this.state.emailIsValid) {
            if (this.state.phone.length > 0) {
                if (this.state.phoneIsValid) {
                    this.createTicket(this.state);
                } else {
                    console.log("Phone Number Invalid");
                    return;
                }
            } else {
                this.createTicket(this.state);
            }
        } else {
            if (!this.state.bodyIsValid) {
                console.log("Ticket Body Required");
            } 
            if (this.state.email.length < 1) {
                console.log("Email is Required");
            }
            return
        }
    }

    render() {
        return (
            <Paper className="new-ticket-form-cont">
                <Typography className="new-ticket-form-title" style={{marginBottom: "10px"}} variant="h4">New Ticket</Typography>
                <form className="new-ticket-form">
                    <TextField 
                        className="new-ticket-form-field" 
                        style={{marginBottom: "20px"}} 
                        onChange={this.handleSubjectChange} 
                        id="subject" label="Subject (Optional)" 
                        variant="outlined" 
                        fullWidth />

                    <TextField 
                        className="new-ticket-form-field" 
                        style={{marginBottom: "20px"}} 
                        onChange={this.handleBodyChange} 
                        id="body" 
                        error={!this.state.bodyIsValid && this.state.body.length > 0} 
                        label={this.state.bodyIsValid || this.state.body.length === 0 ? "Whats Wrong?" : "This Field is Required"}
                        variant="outlined" 
                        fullWidth 
                        multiline 
                        rows={8} />

                    <TextField 
                        className="new-ticket-form-field" 
                        style={{marginBottom: "20px"}} 
                        onChange={this.handleEmailChange} 
                        id="email" 
                        error={!this.state.emailIsValid && this.state.email.length > 0} 
                        label={this.state.emailIsValid || this.state.email.length === 0 ? "Contact Email" : "Email Address Invalid"} 
                        variant="outlined" 
                        fullWidth />

                    <TextField 
                        className="new-ticket-form-field" 
                        style={{marginBottom: "20px"}} 
                        onChange={this.handlePhoneChange} 
                        value={this.state.phone} 
                        error={!this.state.phoneIsValid && this.state.phone.length > 0} 
                        label={this.state.phoneIsValid || this.state.phone.length === 0 ? "Contact Phone Number (Optional)" : "Phone Number Invalid"} 
                        id="phone"  
                        variant="outlined" 
                        fullWidth />

                        
                    <Button className="new-ticket-form-submit" style={{display: "block", margin: "auto"}} onClick={this.handleSubmit} variant="contained" color="primary">Submit Ticket</Button>
                </form>
            </Paper>
        );
    }
}

export default NewTicketForm;